import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './components/home'

import './assets/css/App.css';

function App() {
  const script = document.createElement("script");
  script.src = "https://kit.fontawesome.com/f3e9e0ab91.js";
  script.async = true;
  document.body.appendChild(script);

  return (
    <Router>
      <Route exact path="/:shipment" component={Home} />
    </Router>
  );
}

export default App;
