import React from 'react';

function ClientInfo({ title, info }) {
  return (
    <div className="tarjetablanca text-left shadow-10 desktop">
      <div className="texto-rojo font-bold titulo-tarjeta">{title}</div>
      <div className="line-height160">
        <div className="texto-gris">Correo electrónico</div>
        <div className="texto-azul font-bold text-uppercase">{info.Email ? info.Email : 'NO DISPONIBLE'}</div>
        {info.CompanyName ? <div className="texto-gris">Compañia</div> : null}
        {info.CompanyName ? <div className="texto-azul font-bold text-uppercase">{info.CompanyName}</div> : null}
        {info.Name ? <div className="texto-gris">Nombre</div> : null}
        {info.Name ? <div className="texto-azul font-bold text-uppercase">{info.Name}</div> : null}
        {info.Phone1 ? <div className="texto-gris">Teléfono fijo</div> : null}
        {info.Phone1 ? <div className="texto-azul font-bold text-uppercase">{info.Phone1}</div> : null}
        {info.Phone2 ? <div className="texto-gris">Teléfono celular</div> : null}
        {info.Phone2 ? <div className="texto-azul font-bold text-uppercase">{info.Phone2}</div> : null}
      </div>
    </div>
  )
}

export default ClientInfo;
