import React from 'react';

function PackgeInfo({ info }) {
  return (
    <div className="tarjetagris">
      <p className="titulo-tarjeta font-bold">Datos del paquete</p>
      <div className="table-responsive">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th scope="col">Peso cotizado (KG)</th>
              <th scope="col">Peso volumétrico (KG)</th>
              <th scope="col">Sobrepeso (KG)</th>
              <th scope="col">Peso real (KG)</th>
              <th scope="col">Alto (CM)</th>
              <th scope="col">Ancho (CM)</th>
              <th scope="col">Largo (CM)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{info.Weight} Kg</td>
              <td>{info.VolumetricWeight} Kg</td>
              <td>{info.OverWeight} Kg</td>
              <td>{info.RealWeight} Kg</td>
              <td>{info.Height} cm</td>
              <td>{info.Width} cm</td>
              <td>{info.Length} cm</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  )
}

export default PackgeInfo;
