import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import axios from 'axios';
// Modules
import ClientInfo from './clientInfo';
// import Article from './article';
import PackageInfo from './packageInfo';
import HistoryItem from './historyItem';
// Images (Temporal)
import logo from '../assets/img/logos/logo_obscuro_pakke.png';
import diablito from '../assets/img/logos/diablito.png';

import _99M from '../assets/img/couriers/99M.png';
import _UPS from '../assets/img/couriers/UPS.png';
import _STF from '../assets/img/couriers/STF.png';
import _FDX from '../assets/img/couriers/FDX.png';
import _RPK from '../assets/img/couriers/RPK.png';
import _SRV from '../assets/img/couriers/SRV.png';
import _RSV from '../assets/img/couriers/RSV.png';

import _GREEN from '../assets/img/bullets/ok.png';
import _GRAY from '../assets/img/bullets/pending.png';
import _YELLOW from '../assets/img/bullets/alert.png';


// import Tenis from '../assets/img/Tenis.jpg';
// import tenis from '../assets/img/tenis.png';
// import chela from '../assets/img/chela.jpg';
import _404 from '../assets/img/404.png';

function Home() {

  const { shipment } = useParams();

  const getShipment = async (shipment) => {
    setRequestdone(true);
    try {
      const result = await axios.get(`https://seller.pakke.com.co/api/v1/Shipments/tracking?trackingNumber=${shipment}`)
      let shipmentResult = result.data[0];
      shipmentResult.Parcel.RealWeight = shipmentResult.RealWeight;
      shipmentResult.Parcel.OverWeight = shipmentResult.OverWeight;

      setLoading(false);
      setShipmentData(shipmentResult)
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  }

  const filterStatus = (status) => {
    let state = '';
    switch (status) {
      case 'DELIVERED':
        state = 'ENTREGADO';
        break;
      case 'ON_DELIVERY':
        state = 'POR ENTREGARSE';
        break;
      case 'IN_TRANSIT':
        state = 'EN TRANSITO';
        break;
      case 'RETURNED':
        state = 'REGRESADO';
        break;
      case 'EXCEPTION':
        state = 'CON EXCEPCIÓN';
        break;
      default:
        state = status;
    }
    return state;
  }

  const getBulletStatus = (index) => {
    if (index > 0) return _GREEN;

    let state = '';
    switch (shipmentData.TrackingStatus) {
      case 'DELIVERED':
        state = _GREEN;
        break;
      case 'EXCEPTION':
        state = _YELLOW;
        break;
      default:
        state = _GRAY;
    }
    return state;
  }

  const getBorderClass = (index) => {
    if (index > 0) return '';

    if (shipmentData.TrackingStatus !== 'DELIVERED')
      return 'border-step-gray';

    return '';
  }

  const getLogo = (courier) => {
    let logo = '';
    switch (courier) {
      case '99M':
        logo = _99M;
        break;
      case 'UPS':
        logo = _UPS;
        break;
      case 'STF':
        logo = _STF;
        break;
      case 'FDX':
        logo = _FDX;
        break;
      case 'RPK':
        logo = _RPK;
        break;
      case 'SRV':
        logo = _SRV;
        break;
      case 'RSV':
        logo = _RSV;
        break;
      default:
        logo = '';
    }
    return logo;
  }

  // const [images] = useState([
  //   {
  //     img: Tenis,
  //     title: 'Tenis negros de moda',
  //     description: 'Tenis hechos en de vinipiel italianos',
  //     category: 'Calzado'
  //   },
  //   {
  //     img: tenis,
  //     title: 'Tenis Converse color verdes',
  //     description: 'Tenis hechos en tela marca converse color verde acqua.',
  //     category: 'Calzado'
  //   },
  //   {
  //     img: chela,
  //     title: 'Cerveza Corona',
  //     description: 'Cerveza mexicana',
  //     category: 'Bebida alcohólica'
  //   },
  //   {
  //     img: tenis,
  //     title: 'Tenis Converse color verdes',
  //     description: 'Tenis hechos en tela marca converse color verde acqua.',
  //     category: 'Calzado'
  //   }
  // ]);

  const [requestdone, setRequestdone] = useState(false);
  const [shipmentData, setShipmentData] = useState(null);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (requestdone === false) {
      getShipment(shipment)
    }
  });

  return (
    <div>
      <div className={loading ? '' : 'fondo-general-movil'}>

        <div className="container margin-top20 border-bottom10 padding-bottom desktop">
          <div className="row">
            <div className="col-6 col-sm-6 col-lg-4">
              <img className="img-fluid mx-auto d-block logos-header" src={logo} alt="logo" />
            </div>
            <div className="col-6 col-sm-6 col-lg-4">
              {shipmentData ?
                <img className={`img-fluid mx-auto d-block logos-header courier-logo ${shipmentData.CourierCode}`} src={getLogo(shipmentData.CourierCode)} alt="Logo Pakke" />
                : null}
            </div>
            <div className="col-12 col-sm-12 col-lg-4">
              <div className="tarjetaguias text-responsive-left">
                <p>
                  <span className="texto-rojo">No. de guía </span>
                  <span className="shipment-number"><a target="_blank" title="Número de guía">{`  ${shipment}`}</a></span>
                  <br />
                  {/* <span className="texto-rojo">No. de pedido</span>
                <span><a target="_blank" title="Número de pedido">12345</a></span> */}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container mobile">
          <div className="row">
            <div className="col-6">
              <img src={logo} alt="Logo Pakke" className="img-fluid d-block" />
            </div>
            <div className="col-6 text-center">
              {shipmentData ?
                <img src={getLogo(shipmentData.CourierCode)} alt="Logo Pakke" className={`img-fluid courier-logo ${shipmentData.CourierCode}`} />
                : null}
            </div>
          </div>

          {shipmentData ?
            <div className="row guia-movil text-uppercase">
              <div className="col-12 text-center text-uppercase font-weight-bold mi-pedido">
                Información del envío
            </div>
              <div className="col-12 text-center">
                No. de Guía
              <br />
                <b>{shipment}</b>
              </div>
            </div>
            : null}

          {shipmentData ?
            <div className="row">
              <div className="col-12 estatus-movil text-center">
                <p className="font-bold text-capitalize">Estatus Actual</p>
                <p className="texto-verde font-bold">{filterStatus(shipmentData.TrackingStatus)}</p>
              </div>
            </div>
            : null}

          {shipmentData ?
            <div className="row">
              <div className="col-12 destinatario-movil">
                <div className="row border-destinatario-movil">
                  <div className="col-9">
                    <span className="subtitulo-destino-movil">Remitente:</span>
                    <br />
                    <b className="text-uppercase">{shipmentData.Sender.Name}</b>
                  </div>
                  <div className="col-3">
                    <i className="fas fa-box-open"></i>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="subtitulo-destino-movil">Correo electrónico:</span>
                    <br />
                    <b>{shipmentData["Sender"].Email ? shipmentData["Sender"].Email : 'NO DISPONIBLE'}</b>
                  </div>
                  <div className="col-6">
                    <span className="subtitulo-destino-movil">Compañia:</span>
                    <br />
                    <b>{shipmentData["Sender"].CompanyName}</b>
                  </div>
                  <div className="col-6">
                    <span className="subtitulo-destino-movil">Dirección:</span>
                    <br />
                    <b>{shipmentData.AddressFrom.Address1}, {shipmentData.AddressFrom.City}, C.P. {shipmentData.AddressFrom.ZipCode}</b>
                  </div>
                  <div className="col-6">
                    <span className="subtitulo-destino-movil">Teléfono de contacto:</span>
                    <br />
                    <b>{shipmentData.Sender.Phone1}</b>
                  </div>
                </div>
              </div>

              <div className="col-12 destinatario-movil">
                <div className="row border-destinatario-movil">
                  <div className="col-9">
                    <span className="subtitulo-destino-movil">Destinatario:</span>
                    <br />
                    <b className="text-uppercase">{shipmentData.Recipient.Name}</b>
                  </div>
                  <div className="col-3">
                    <i className="fas fa-people-carry"></i>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="subtitulo-destino-movil">Correo electrónico:</span>
                    <br />
                    <b>{shipmentData.Recipient.Email ? shipmentData.Recipient.Email : 'NO DISPONIBLE'}</b>
                  </div>
                  <div className="col-6">
                    <span className="subtitulo-destino-movil">Compañia:</span>
                    <br />
                    <b>{shipmentData.Recipient.CompanyName ? shipmentData.Recipient.CompanyName : 'NO DISPONIBLE'}</b>
                  </div>
                  <div className="col-6">
                    <span className="subtitulo-destino-movil">Dirección:</span>
                    <br />
                    <b>{shipmentData.AddressTo.Address1}, {shipmentData.AddressTo.City}, C.P. {shipmentData.AddressTo.ZipCode}</b>
                  </div>
                  {shipmentData.Recipient.Phone1 ?
                    <div className="col-6">
                      <span className="subtitulo-destino-movil">Teléfono fijo:</span>
                      <br />
                      <b>{shipmentData.Recipient.Phone1}</b>
                    </div>
                    : null}
                </div>
              </div>

            </div>
            : null}
        </div>

        {error ? (
          <div className="error-message">
            <img src={_404} alt="" />
            <p>
              El número de guía no fue encontrado
          </p>
            <p class="error-message-footer">
              Asegúrate de haberlo ingresado correctamente.
            <span>
                Si necesitas ayuda, contáctanos a través del chat disponible en nuestra página o al correo:
            <br />
                <strong>soporte@pakke.mx</strong>
              </span>
            </p>
          </div>
        ) : null}

        {loading ? (
          <div className="loading-message">
            <p>
              Buscando la guía...
          </p>
          </div>
        ) : null}

        {shipmentData ?
          <div className="container margin-top50 desktop">
            <div className="row bordes text-center text-uppercase padding-t-b">
              <div className="col-6 col-sm-6 col-md-4 col-lg-4" >
                <p className="font-bold text-capitalize margin-bottom">Remitente</p>
                <p className="margin-top20">{shipmentData.Sender.Name}</p>
                <p className="font-size12 margin-top20">{shipmentData.AddressFrom.Address1}, {shipmentData.AddressFrom.City}, C.P. {shipmentData.AddressFrom.ZipCode}</p>
                {/* <p className="margin-top20 font-size12">Fecha de envio 06/06/2019</p> */}
              </div>
              <div className="col-6 col-sm-6 col-md-4 col-lg-4" >
                <p className="font-bold text-capitalize margin-bottom">Estatus Actual</p>
                <p className="texto-verde font-bold margin-top20">{filterStatus(shipmentData.TrackingStatus)}</p>
                {/* <a title="Solicitar notificaciones" target="_blank">Solicitar notificaciones</a> */}
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 margin-destin" >
                <p className="font-bold text-capitalize margin-bottom">Destinatario</p>
                <p className="margin-top20">{shipmentData.Recipient.Name}</p>
                <p className="font-size12 margin-top20">{shipmentData.AddressTo.Address1}, {shipmentData.AddressTo.City}, C.P. {shipmentData.AddressTo.ZipCode}</p>
                {/* <p className="margin-top20 font-size12">Fecha de entrega 08/06/2019</p> */}
              </div>
            </div>
            <div className="row border-bottom10 margin-top50">
              <div className="col-12">
                <img className="img-fluid mx-auto d-block diablito" src={diablito} alt="diablito" />
              </div>
            </div>
          </div>
          : null}

        {
          shipmentData ?
            <div className="container margin-top100">

              <div className="row">

                <div className="col-12 col-sm-12 col-lg-6">
                  {shipmentData ? <PackageInfo info={shipmentData.Parcel} /> : null}

                  {shipmentData ? <ClientInfo title="Contacto de remitente" info={shipmentData.Sender} /> : null}

                  {shipmentData ? <ClientInfo title="Contacto de destinatario" info={shipmentData.Recipient} /> : null}

                  {/* <div className="tarjetablanca text-left">
              <div className="font-bold titulo-tarjeta">Productos del pedido</div>
              <div className="section container-scroll">
                {images.map((item, i) => (
                  <Article item={item} key={`art-${i.toString()}`} />
                ))}
              </div>
            </div> */}

                </div>


                <div className="col-12 col-sm-12 col-lg-6">


                  <div className="tarjetagris historial">
                    <div className="row">
                      <div className="col-12 margin-bottom">
                        <p className="titulo-tarjeta font-bold">Historial</p>
                      </div>

                      <div className="col-11">

                        {shipmentData && shipmentData["History"].length > 0 && show ?
                          shipmentData["History"].map((item, i) => (
                            <HistoryItem key={`history-item-${i.toString()}`}
                              item={item}
                              index={i}
                              dataLength={shipmentData["History"].length - 1}
                              getBulletStatus={getBulletStatus}
                              getBorderClass={getBorderClass}
                            />
                          ))
                          : null}
                        {shipmentData && shipmentData["History"].length > 0 && !show ?
                          shipmentData["History"].slice(0, 6).map((item, i) => (
                            <HistoryItem key={`history-item-${i.toString()}`}
                              item={item}
                              index={i}
                              dataLength={shipmentData["History"].length > 6 ? 5 : shipmentData["History"].length - 1}
                              getBulletStatus={getBulletStatus}
                              getBorderClass={getBorderClass}
                            />
                          ))
                          : null}

                        {shipmentData && shipmentData["History"].length > 6 ?
                          <div className="container no-padding">
                            <div className="panel-group" id="accordion">
                              <div className="panel panel-default">

                                <div className="panel-body">
                                  <a className="collapsed button blue texto-collapse" onClick={() => setShow(!show)}>
                                    {show ? <i className="fas fa-sort-up"></i> : <i className="fas fa-sort-down"></i>}
                                    {show ? `  Ver menos` : `  Ver más`}
                                  </a>
                                </div>

                              </div>
                            </div>
                          </div>
                          : null}

                      </div>

                    </div>

                  </div>

                </div>
              </div>
            </div>
            : null
        }

      </div>
      {
        shipmentData ?
          <footer className="section footer">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 text-responsive-left"><p>©2019 Pakke. Todos los derechos reservados.</p></div>
                <div className="col-12 col-sm-6 text-responsive-right margin-movil-terminos">
                  <a href="http://help.pakke.mx/aviso-de-privacidad/" title="Aviso de privacidad"> Aviso de privacidad</a>
                  <a href="http://help.pakke.mx/terminos-y-condiciones/" title="Terminos y Condiciones">| Términos y condiciones</a>
                </div>
              </div>
            </div>
          </footer>
          : null
      }

    </div >
  );
}

export default Home;
